import { useState } from 'react';
import { FaEnvelope, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { env } from '../config/env';
import logger from '../utility/logger';
import PasswordCriteria from '../components/PasswordCriteria';
import {translations} from '../utility/translations';

const RegisterForm = () => {
    const navigate = useNavigate();
    const [passwordShown, setPasswordShown] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [displayText, setDisplayText] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: ''
    });
    const [touched, setTouched] = useState({
        name: false,
        email: false,
        password: false
    });
    const [validity, setValidity] = useState({
        name: true,
        email: true,
        password: true
    });

    const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isValidPassword = password => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{10,}$/.test(password);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'email') {
            setValidity({ ...validity, email: isValidEmail(value) });
        } else if (name === 'password') {
            setValidity({ ...validity, password: isValidPassword(value) });
        }
    };

    const handleBlur = e => {
        const { name } = e.target;
        setTouched({ ...touched, [name]: true });
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    const canSubmit = formData.name && validity.email && validity.password;

    const handleGoToLogin = e => {
        e.preventDefault();
        navigate('/login');
    };

    const handleRegister = async e => {
        e.preventDefault();

        if (!canSubmit) {
            return;
        }

        try {
            const response = await fetch(`${env.api_url}/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: formData.name,
                    email: formData.email,
                    password: formData.password
                })
            });

            const data = await response.json();
            if (response.ok) {
                setShowSuccess(true);
                setShowError(false);
                logger.info('Registration successful:', JSON.stringify(data));
                setFormData({ name: '', email: '', password: '' });
                setTimeout(() => navigate('/login'), 3800);
                return;
            } else {
                setShowSuccess(false);
                setShowError(true);
                setDisplayText(translations(data.error));
                return;
            }
        } catch (e) {
            setShowSuccess(false);
            setShowError(true);
            setDisplayText(translations('GENERIC_ERROR')); // Call the hook directly in the component
        }
    };

    return (
        <>
            {showSuccess && (
                <div className="alert alert-success" role="alert">
                    Account created successfully. Please check your email to login.
                </div>
            )}
            {showError && (
                <div className="alert alert-danger" role="alert">
                    {displayText}
                </div>
            )}
            <div className="container">
                <h2>Register</h2>
                <form onSubmit={handleRegister}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className={`form-control ${touched.name && !formData.name ? 'is-invalid' : ''}`}
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.name && !formData.name && <div className="invalid-feedback">Name is required.</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><FaEnvelope /></span>
                            </div>
                            <input
                                type="email"
                                className={`form-control ${touched.email && !validity.email ? 'is-invalid' : ''}`}
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && !validity.email && <div className="invalid-feedback">Please enter a valid email. Example: user@example.com</div>}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div className="input-group">
                            <input
                                type={passwordShown ? "text" : "password"}
                                className={`form-control ${touched.password && !validity.password ? 'is-invalid' : ''}`}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text" type="button" onClick={togglePasswordVisiblity}>
                                    {passwordShown ? <FaEye /> : <FaEyeSlash />}
                                </span>
                            </div>
                            {touched.password && !validity.password && (
                                <div className="invalid-feedback">
                                    Password must contain at least one uppercase, one lowercase, and one number or special character.
                                </div>
                            )}
                        </div>
                        <PasswordCriteria password={formData.password} />
                    </div>
                    <div className="d-grid gap-2 mt-4">
                        <button type="submit" className="btn btn-lg btn-primary" disabled={!canSubmit}>
                            Create Account
                        </button>
                    </div>
                    <div className="d-grid gap-2">
                        <button className="btn btn-link btn-secondary" onClick={handleGoToLogin}>
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default RegisterForm;

import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';

const SwipeCard = ({
    children,
    onSwipe,
    setIsDragging, // Assume this function sets a state in the parent component.
    directionsConfig = {
        left: true,
        right: true,
        up: true,
        down: false
    }
}) => {
    const [{ xy }, api] = useSpring(() => ({
        xy: [0, 0],
        config: { mass: 1, tension: 500, friction: 30 },
        onRest: () => api.start({ xy: [0, 0] })  // Ensure card resets to center after animation
    }));

    const bind = useDrag(({ down, movement: [mx, my], direction: [xDir, yDir], velocity, distance }) => {
        const trigger = velocity > 0.2 && distance > 50;
        const isHorizontal = Math.abs(xDir) > Math.abs(yDir);

        if (down && distance > 10) {
            setIsDragging(true);  // Set dragging state to true when movement starts
        }

        let dir = null;
        let targetX = 0;
        let targetY = 0;

        if (isHorizontal) {
            if (xDir < 0 && directionsConfig.left) {
                dir = 'left';
                targetX = -window.innerWidth * 2;  // Swipe left
            } else if (xDir > 0 && directionsConfig.right) {
                dir = 'right';
                targetX = window.innerWidth * 2;  // Swipe right
            }
        } else {
            if (yDir < 0 && directionsConfig.up) {
                dir = 'up';
                targetY = -window.innerHeight * 2;  // Swipe up
            } else if (yDir > 0 && directionsConfig.down) {
                dir = 'down';
                targetY = window.innerHeight * 2;  // Swipe down
            }
        }

        if (!down) {
            setIsDragging(false);  // Reset dragging state when user releases the mouse/touch
            if (trigger && dir) {
                onSwipe(dir);
                api.start({ xy: [targetX, targetY] });
            } else {
                // Reset the position if the swipe didn't trigger
                api.start({ xy: [0, 0] });
            }
        } else {
            // Update position during drag
            api.start({ xy: [mx, my], immediate: down });
        }
    });

    return (
        <animated.div
            {...bind()}
            style={{
                transform: xy.to((x, y) => `translate3d(${x}px,${y}px,0)`),
                touchAction: 'none',  // Improve dragging performance on touch devices
            }}
        >
            {children}
        </animated.div>
    );
};

export default SwipeCard;

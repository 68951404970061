import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { env } from '../config/env';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const ChangePassword = (props) => {
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const validateForm = useCallback(() => {
        const { currentPassword, newPassword, confirmPassword } = formData;
        const isDisabled = !currentPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword;
        props._callbackIsPWOk(!isDisabled);
    }, [formData, props]);

    useEffect(() => {
        validateForm();
    }, [formData, validateForm]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);

    const togglePasswordVisibility = useCallback(() => {
        setPasswordShown(!passwordShown);
    }, [passwordShown]);

    return (
        <form id="changePasswordForm">
            {successMessage && (
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>
            )}
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
            <div className="form-group">
                <label htmlFor="currentPassword">Current Password</label>
                <div className="input-group">
                    <input
                        type="password"
                        className="form-control"
                        id="currentPassword"
                        name="currentPassword"
                        value={formData.currentPassword}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="newPassword">New Password</label>
                <div className="input-group">
                    <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        id="newPassword"
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleChange}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                            {passwordShown ? <FaEye /> : <FaEyeSlash />}
                        </button>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="confirmPassword">Confirm New Password</label>
                <div className="input-group">
                    <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                            {passwordShown ? <FaEye /> : <FaEyeSlash />}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ChangePassword;

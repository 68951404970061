import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import DialogModal from '../components/dialog-modal';
import { AuthContext, useAuth } from '../context/AuthContext';
import { env } from '../config/env';
import ImageUploadField from "../components/ImageUploadField";
import ChangePassword from '../components/change-password';
import logger from "../utility/logger";

const HumanProfile = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const uploaderRef = useRef();
    const { userId, accessToken } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState(''); // State for image upload message
    const [profile, setProfile] = useState({
        name: '',
        profile_img: '',
        dob: '',
        postalCode: ''
    });
    const [initialProfile, setInitialProfile] = useState(null); // State for storing initial profile
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPwModalOpen, setIsPwModalOpen] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false); // State for button enabled/disabled
    const [isOkToSubmit, setIsOkToSubmit] = useState(false);
    const [isOkToSubmitPw, setIsOkToSubmitPw] = useState(false);
    const [selectedImageFile, setSelectedImageFile] = useState(null); // Store the selected file

    const setErrorMessages = (error) => {
        setErrorMessage(error);
        setTimeout(() => {
            setErrorMessage('');
        }, 4000);
    };

    useEffect(() => {
        const fetchHumanProfile = async () => {
            try {
                const res = await fetch(`${env.api_url}/user-profile?x=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!res.ok) throw new Error('Failed to fetch');

                const data = await res.json();
                if (Object.keys(data.data).length === 0) return;

                data.data.dob = data.data.dob.split('T')[0];
                const scrubbedProfile = scrubProfile(data.data);
                setProfile(scrubbedProfile);
                setInitialProfile(scrubbedProfile); // Set initial profile state
            } catch (err) {
                logger.log(err);
            }
        };
        fetchHumanProfile();
    }, [userId, accessToken]);

    const scrubProfile = (profile) => {
        const scrubbedProfile = {};
        for (const [key, value] of Object.entries(profile)) {
            scrubbedProfile[key] = value || '';
        }
        return scrubbedProfile;
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        if (!profile.name || !profile.dob || !profile.postalCode) {
            logger.log('All fields are required');
            setErrorMessages('All fields are required');
            return;
        }

        try {
            const res = await fetch(`${env.api_url}/user-profile`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: profile.name, dob: profile.dob, postalCode: profile.postalCode, userId })
            });

            if (!res.ok) throw new Error('Failed to Update');

            const data = await res.json();
            data.data.dob = data.data.dob.split('T')[0];
            const updatedProfile = scrubProfile(data.data);
            setProfile(updatedProfile);
            setInitialProfile(updatedProfile); // Update initial profile after saving
            setIsSaveEnabled(false); // Disable save button
        } catch (err) {
            logger.log(err);
            setErrorMessages('Failed to Update');
        }
    };

    const handleCloseModalSubmit = async () => {
        if (!selectedImageFile) {
            logger.log('No file selected');
            return;
        }

        const formData = new FormData();
        formData.append('profile_img', selectedImageFile);
        formData.append('userId', userId);

        try {
            const res = await fetch(`${env.api_url}/user-profile-img`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!res.ok) throw new Error('Failed to upload image');

            const data = await res.json();
            setIsModalOpen(false);
            uploaderRef.current.resetState();
            data.data.dob = data.data.dob.split('T')[0];
            setProfile(scrubProfile(data.data));
            setInfoMessage('Profile image updated, please save to confirm.');
        } catch (err) {
            logger.log(err);
        }
    };

    const handleFormInput = (e) => {
        const { name, value } = e.target;
        const updatedProfile = { ...profile, [name]: value };
        setProfile(updatedProfile);
        setIsSaveEnabled(JSON.stringify(updatedProfile) !== JSON.stringify(initialProfile)); // Enable save if profile changed
    };

    const handleFilesSelected = (files) => {
        if (files && files[0]) {
            setSelectedImageFile(files[0]);
            setIsOkToSubmit(true);
            setInfoMessage('You have a new profile image, please save to apply changes.');
            setIsSaveEnabled(true); // Enable the button when a file is selected
        } else {
            setIsOkToSubmit(false);
            setInfoMessage('');
            // Optionally, disable the save button if needed
            // setIsSaveEnabled(false);
        }
    };

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
        navigate('/login');
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        uploaderRef.current.resetState();
    };

    const handleGoToChangePassword = (e) => {
        e.preventDefault();
        setIsPwModalOpen(true);
    };

    return (
        <>
            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
            {infoMessage && <div className="alert alert-info" role="alert">{infoMessage}</div>}
            <div className="container">
                <div className='mt-5'>
                    {!profile.profile_img && <img className="profile-img" src='./static/guest-user.webp' alt="Profile" onClick={() => setIsModalOpen(true)} />}
                    {profile.profile_img && <img className="profile-img" src={`${env.profile_img_url}/${profile.profile_img}`} alt="Profile" onClick={() => setIsModalOpen(true)} />}
                </div>
                <div className="profile-form" style={{ textAlign: 'center' }}>
                    <input type="text" id="name" name="name" placeholder="Your name" value={profile.name} onChange={handleFormInput} />
                    <input type="text" id="postalCode" name="postalCode" placeholder="Postal Code" value={profile.postalCode} onChange={handleFormInput} />
                    <input type="date" id="dob" name="dob" value={profile.dob} onChange={handleFormInput} />
                    <div className="d-grid gap-1 mt-2">
                        <a href="#update" className={`btn btn-lg btn-primary ${isSaveEnabled ? '' : 'disabled'}`} onClick={isSaveEnabled ? handleSubmitForm : null}>Save Profile</a>
                        <a href="#password" className="btn btn-lg btn-primary" onClick={handleGoToChangePassword}>Change Password</a>
                    </div>

                    <div className="sign-out mt-2">
                        <a href="#logout" className="btn btn-danger mt-5 text-white" onClick={handleLogout}>Sign Out</a>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <DialogModal
                    title="Profile Image"
                    body={
                        <div className="mb-3">
                            <label htmlFor="petImage" className="form-label">Upload Profile Image</label>
                            <ImageUploadField onFilesSelected={handleFilesSelected} allowMultiple={false} ref={uploaderRef} />
                        </div>
                    }
                    closeDialog={handleCloseModal}
                    closeDialogSubmit={handleCloseModalSubmit}
                    closeDialogSubmitDisabled={!isOkToSubmit}
                    closeText="Cancel"
                    closeSubmitText="Upload Image"
                />
            )}
            {isPwModalOpen && (
                <DialogModal
                    title="Change Password"
                    body={
                        <ChangePassword _callbackIsPWOk={(isOk) => setIsOkToSubmitPw(isOk)} />
                    }
                    closeDialog={handleClosePwModal}
                    closeDialogSubmit={handleClosePwModalSubmit}
                    closeDialogSubmitDisabled={!isOkToSubmitPw}
                    closeText="Cancel"
                    closeSubmitText="Change Password"
                />
            )}
        </>
    );
};

export default HumanProfile;

import React, { useState, useEffect, useRef, useContext, useCallback, forwardRef } from 'react';
import { env } from '../config/env';
import logger from "../utility/logger";
import { AuthContext } from '../context/AuthContext';
import { UserContext } from '../context/UserContext';
import { humanDateShortestNoTime } from '../utility/dates';
import DialogModal from '../components/dialog-modal';
import { PetResponse } from '../components/dialog-templates';

function Messages() {
    const { userId, accessToken } = useContext(AuthContext);
    const { fetchCounts } = useContext(UserContext);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [whichMailbox, setWhichMailbox] = useState('inbox');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOkToSubmit, setIsOkToSubmit] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [modalData, setModalData] = useState({});

    const fetchMessages = async (val = 'inbox', sort = 'DESC') => {
        setLoading(true);
        setError(false);
        setMessages([]);
        setWhichMailbox(val);
        setSortOrder(sort);
        await fetch(`${env.api_url}/messages?x=${userId}&t=${val}&s=${sort}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (!res.ok) {
                    logger.error('Failed to fetch');
                    throw new Error('Failed to fetch');
                }
                return res.json();
            })
            .then(res => {
                setMessages(res.data);
                setLoading(false);
            })
            .catch(err => {
                logger.error(err);
                setError(err);
            });
    }

    useEffect(() => {
        let isMounted = true; // track whether component is mounted
        fetchMessages().then(() => {
            if (isMounted) setLoading(false);
        });
        return () => { isMounted = false }; // cleanup function to be called when component unmounts
    }, []);

    const handleCloseModal = () => {
        setErrorMessage('');
        setSuccessMessage('');
        setIsModalOpen(false);
        setIsOkToSubmit(false);
    }

    const handleCloseModalSubmit = async () => {
        // Send message
        setErrorMessage('');
        setSuccessMessage('');

        if (!modalData.message) {
            setErrorMessage('Please select a message');
            return;
        }

        // createMessageEntry(senderUserId = 0, recipientUserId = 0, data = {}, $metadata = '')
        // { x: userId, y: recipientUserId, z: message, m: metadata }

        await fetch(`${env.api_url}/messages`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                x: userId,
                y: modalData.senderUserId,
                s: `Re: ${modalData.msgSubject}`,
                m: modalData.message,
                metadata: JSON.stringify({ ...modalData.metadata }),
                messageId: modalData.messageId
            })
        })
            .then(res => {
                if (!res.ok) {
                    logger.error('Failed to send message');
                    return;
                }
                return res.json();
            })
            .then(res => {
                setSuccessMessage('Message sent');
                setIsOkToSubmit(false);
                fetchMessages(whichMailbox);
            })
            .catch(err => {
                setErrorMessage('Failed to send message');
                logger.error(err);
            });
    }

    const handleDeleteMessage = async (e) => {
        e.stopPropagation();
        const messageId = e.target.getAttribute('data-message-id');
        await fetch(`${env.api_url}/messages/${messageId}/${userId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (!res.status === 204) {
                    logger.log('Failed to delete message');
                    return;
                }
                console.log('Message deleted')
                fetchMessages(whichMailbox);
            })
            .catch(err => {
                logger.error(err);
            });
    }

    const handleOpenModalDialog = (data) => {

        setModalData(data);
        setIsModalOpen(true);
    }

    const setMessageAsRead = async (messageId) => {
        await fetch(`${env.api_url}/message/${messageId}/action?x=read`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, messageId })
        })
            .then(res => {
                if (!res.ok) {
                    logger.error('Failed to mark as read');
                    return;
                }
                return res.json();
            })
            .then(res => {
                console.log('Message marked as read');
                fetchMessages(whichMailbox);
                fetchCounts();
            })
            .catch(err => {
                logger.error(err);
            });
    }

    return (
        <div className="formContainer">
            {
                loading ? <h1>Loading...</h1> :
                    error ? <h1>Error: {error}</h1> :
                        <div>
                            <h2>Messages</h2>
                            <select
                                value={whichMailbox}
                                className="form-select mb-3"
                                onChange={(e) => {
                                    fetchMessages(e.target.value);
                                }}
                            >
                                <option value="inbox">Inbox</option>
                                <option value="sent">Sent</option>
                                <option value="trash">Trash</option>
                            </select>
                            {
                                whichMailbox === 'trash' || whichMailbox === 'sent' && <div className="alert alert-warning">Messages marked as <strong>{whichMailbox}</strong> will be deleted after 30 days</div>
                            }
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">From</th>
                                        <th scope="col">Subject</th>
                                        <th scope="col">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        messages.length === 0 ?
                                            <tr><td colSpan="4" className="text-center p-3">No messages</td></tr>
                                            :
                                            messages.map((message) => {
                                                return (
                                                    <tr
                                                        key={message.msgId}
                                                        onClick={() => {
                                                            if (whichMailbox === 'inbox' && parseInt(message.isReadRecipient) !== 1 && message.recipientUserId === parseInt(userId)) {
                                                                setMessageAsRead(message.msgId);
                                                            }

                                                            if (whichMailbox === 'sent' && parseInt(message.isReadSender) !== 1 && message.senderUserId === parseInt(userId)) {
                                                                setMessageAsRead(message.msgId);
                                                            }

                                                            handleOpenModalDialog({ ...message, metadata: JSON.parse(message.metadata) })
                                                        }}
                                                        style={{ fontWeight: message.isReadRecipient ? 'normal' : 'bold' }}
                                                    >
                                                        <td>{humanDateShortestNoTime(message.createdDate)}</td>
                                                        <td>{message.name}</td>
                                                        <td>{message.msgSubject}</td>
                                                        <td>
                                                            <span
                                                                data-message-id={message.msgId}
                                                                onClick={handleDeleteMessage}
                                                                style={{ display: whichMailbox === 'trash' ? 'none' : 'inline' }}
                                                            >
                                                                [D]
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
            }
            {
                isModalOpen && <DialogModal
                    title={modalData.msgSubject}
                    body={
                        <PetResponse
                            modalData={modalData}
                            handleMessageChange={(e) => {
                                const val = e.target.value;
                                if (val.length <= 0) {
                                    setIsOkToSubmit(false);
                                } else {
                                    setIsOkToSubmit(true);
                                }
                                setModalData({ ...modalData, message: val });
                            }}
                        />
                    }
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    closeDialog={() => handleCloseModal()}
                    closeDialogSubmit={() => handleCloseModalSubmit()}
                    closeDialogSubmitDisabled={!isOkToSubmit}
                    closeText="Cancel"
                    closeSubmitText="Send Reply"
                    hideCloseOnly={false}
                />
            }
        </div>
    );
}

export default Messages;
export const translationsBundle = {
    'CREATE_INFORMATION_FAILED': 'There was an error creating the user information. Please try again.',
    'CREATE_LOGIN_FAILED_CREATION': 'There was an error creating the login. Please try again.',
    'CREATE_LOGIN_FAILED_GET_USER': 'There was an error retrieving the user ID. Please try again.',
    'CREATE_LOGIN_FAILED_HASH': 'There was an error hashing the password. Please try again.',
    'CREATE_VERIFICATION_FAILED': 'There was an error creating the verification. Please try again.',
    'EMAIL_EMPTY': 'The email field is empty. Please provide a valid email.',
    'EMAIL_INVALID': 'The email provided is invalid. Please provide a valid email.',
    'GENERIC_ERROR': 'An error occurred. Please try again later.',
    'GENERIC_SUCCESS': 'Success!',
    'INVALID_EMAIL': 'There was an error retrieving the email. Please try again later.',
    'METHOD_NOT_SUPPORTED': 'The requested method is not supported on this endpoint.',
    'NAME_EMPTY': 'The name field is empty. Please provide a valid name.',
    'NAME_INVALID': 'The name provided contains invalid characters. Please provide a valid name.',
    'NAME_TOO_LONG': 'The name provided is too long. Please provide a shorter name.',
    'NAME_TOO_SHORT': 'The name provided is too short. Please provide a longer name.',
    'PASSWORD_EMPTY': 'The password field is empty. Please provide a valid password.',
    'PASSWORD_INVALID': 'The password does not meet the required criteria. Please provide a valid password.',
    'PASSWORD_MEDIUM': 'The password is of medium strength. Please provide a stronger password.',
    'PASSWORD_RISKY': 'The password is too weak. Please provide a stronger password.',
    'PASSWORD_TOO_LONG': 'The password provided is too long. Please provide a shorter password.',
    'PASSWORD_TOO_SHORT': 'The password provided is too short. Please provide a longer password.',
    'PASSWORD_TOUGH': 'The password is tough but could be stronger. Please provide a stronger password.',
    'PASSWORD_WEAK': 'The password is weak. Please provide a stronger password.',
    'USER_FOUND': 'A user with this email already exists.',
    'USER_NOT_FOUND': 'No user found with the provided email.'
  };

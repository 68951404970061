import React, { useState, useEffect, useContext } from 'react';
import PetProfileActionMenu from './pet-profile-actions';
import { useParams, useNavigate } from 'react-router-dom';
import { env } from '../config/env';
import logger from "../utility/logger";
import { AuthContext } from '../context/AuthContext';
import { humanDate } from '../utility/dates';

const LostPetProfile = (props) => {
    const navigate = useNavigate();
    const { userId, accessToken } = useContext(AuthContext);
    const { lafId } = useParams();
    const [pet, setPet] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [profileNotFound, setProfileNotFound] = useState(false);
    const [isConfirmedHomeModalOpen, setIsConfirmedHomeModalOpen] = useState(false);

    const fetchLafPet = async () => {
        setLoading(true);
        setError(false);
        await fetch(`${env.api_url}/lost-pet-profile?lafId=${lafId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (!res.ok) {
                    logger.error('Failed to fetch');
                    throw new Error('Failed to fetch');
                }
                return res.json();
            })
            .then(res => {
                if (res.data.length === 0) {
                    setProfileNotFound(true);
                    return;
                }

                setPet(res.data);
                setLoading(false);
            })
            .catch(err => {
                logger.error(err);
                setError(err);
            });
    }

    useEffect(() => {
        let isMounted = true; // track whether component is mounted
        fetchLafPet().then(() => {
            if (isMounted) setLoading(false);
        });
        return () => { isMounted = false }; // cleanup function to be called when component unmounts
    }, []);


    const petStatus = !pet.isFound ? 'Lost' : 'Found';

    if (loading) {
        return <div>Loading...</div>
    }

    if (profileNotFound) {
        return <div>Profile not found</div>
    }

    return (
        <>
            <div className="p-4">
                <div className="d-grid gap-1">
                    <a href="#pets"
                        className="btn btn-lg btn-info"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1)
                        }}
                    >
                        Go Back
                    </a>
                </div>
                <div className="pet-profile mt-4">
                    <div className="pet-image-placeholder mb-4">
                        <div className="status-pill">{petStatus}</div>
                        <img className="rounded mx-auto d-block img-fluid" style={{ maxHeight: '450px' }} src={`${env.pet_img_url}/${pet.imgFileName}`} alt={`My Pet`} />
                        {
                            pet.isActivelyLost && pet.petOwnerId === userId &&
                            <div className="d-grid gap-1">
                                <a href="#pets-home"
                                    className="btn btn-lg btn-info"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsConfirmedHomeModalOpen(true);
                                    }}
                                >
                                    Mark as back home
                                </a>
                            </div>
                        }
                    </div>
                    <h1>Name: {!pet.name ? 'Unknown' : pet.name}</h1>
                    <div><strong>Breed:</strong> {pet.petBreed}</div>
                    <div><strong>Color(s): </strong> {pet.colors}</div>
                    <div><strong>Date {petStatus === 'Lost' ? 'Lost' : 'Found'}:</strong> {humanDate(pet.createdDate)}</div>
                    <div><strong>Location {petStatus === 'Lost' ? 'Lost' : 'Found'}:</strong> {pet.city}, {pet.state_abbr}</div>
                    <div><strong>Description:</strong> {pet.details ? pet.details : 'No description available'}</div>
                </div>
                {/* <PetProfileActionMenu /> */}
            </div>
            {
                isConfirmedHomeModalOpen &&
                <DialogModal
                    title="Remove pet"
                    body={
                        <div>
                            <p>Are you sure you want to remove this pet?</p>
                        </div>
                    }
                    closeDialog={handleCloseModal}
                    closeDialogSubmit={handleCloseModalSubmit}
                    closeText="Cancel"
                    closeSubmitText="Continue"
                    hideCloseOnly={false}
                />
            }
        </>
    );
}

export default LostPetProfile;

import { useState, useContext, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { env } from '../config/env';
import logger from "../utility/logger";
import ImageUploadField from "../components/ImageUploadField";
import { petColors } from "../utility/pet-colors";
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory as FilesystemDirectory } from '@capacitor/filesystem';
import { base64ToBlob } from '../utility/base64ToBlob';
import { toggleArryItem } from '../utility/toggleArrayItem';

function FoundPetForm() {
    const navigate = useNavigate();
    const uploaderRef = useRef();

    const [typeSelections, setTypeSelections] = useState([]);
    const [selectedPetType, setSelectedPetType] = useState('');
    const [selectedPetBreed, setSelectedPetBreed] = useState([]);
    const [selectedPetColor, setSelectedPetColor] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isCapacitor, setIsCapacitor] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false); // State to handle loading
    const [progress, setProgress] = useState(0); // Track upload progress

    const { userId, accessToken } = useContext(AuthContext);

    useMemo(() => {
        if (Capacitor.isNativePlatform()) {
            setIsCapacitor(true);
        }

        fetch(`${env.api_url}/pet-types`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (!res.ok) {
                logger.log('Failed to fetch');
                return;
            }
            return res.json();
        }).then(res => {
            setTypeSelections(res.data);
        }).catch(err => {
            logger.log(err);
        });
    }, [accessToken]);

    const handleMultiSelectBreedChange = (e) => {
        const selectedVlaues = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedPetBreed(selectedVlaues);
    };

    const handleMultiSelectColorChange = (e) => {
        const selectedVlaues = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedPetColor(selectedVlaues);
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();

        setErrorMessage('');
        setSuccessMessage('');
        setLoading(true); // Set loading to true to disable the form
        setProgress(0); // Reset progress bar

        const petType = e.target.petType.value;
        // const petBreed = e.target.petBreed.value;
        // const petColor = e.target.petColor.value;
        const petBreed = selectedPetBreed.join(',');
        const petColor = selectedPetColor.join(',');
        const petLostArea = e.target.petLostArea.value;

        if (!petType || !petBreed || !petColor || selectedFiles.length === 0 || !petLostArea) {
            setErrorMessage('All fields are required');
            setLoading(false); // Re-enable form in case of validation errors
            return;
        }

        const formData = new FormData();
        formData.append('ownerId', userId);
        formData.append('petType', petType);
        formData.append('petBreed', petBreed);
        formData.append('petColor', petColor);
        formData.append('location', petLostArea);

        formData.append('petDescription', '');
        formData.append('name', '');
        formData.append('isFound', '1');
        formData.append('radius', '0');
        formData.append('locationDetails', '');

        // if (isCapacitor) {
        //     try {
        //         const file = selectedFiles[0];
        //         let filePath = file.path || file.webPath;

        //         if (!filePath) {
        //             throw new Error("Invalid file path.");
        //         }

        //         // Handle 'capacitor://localhost' and 'file://' parts
        //         if (filePath.startsWith("capacitor://")) {
        //             const uriResult = await Filesystem.getUri({
        //                 path: filePath.replace("capacitor://localhost/_capacitor_file_", ''),
        //                 directory: FilesystemDirectory.Cache, // Initial directory might be Cache or tmp
        //             });
        //             filePath = uriResult.uri;
        //         } else if (filePath.startsWith("file://")) {
        //             filePath = filePath.replace("file://", '');
        //         }

        //         console.log("Cleaned file path:", filePath);

        //         // Move the file to the Documents directory for persistence
        //         const savedFile = await Filesystem.copy({
        //             from: filePath,
        //             to: `savedPhotos/photo-${Date.now()}.jpg`,  // Use a unique name
        //             directory: FilesystemDirectory.Documents,  // Save in Documents for persistence
        //         });

        //         console.log('File moved to Documents directory:', savedFile.uri);

        //         const fileData = await Filesystem.readFile({
        //             path: savedFile.uri,
        //             directory: FilesystemDirectory.Documents,  // Now reading from Documents
        //         });

        //         const mimeType = file.mimeType || 'image/jpeg';
        //         const blob = base64ToBlob(fileData.data, mimeType);

        //         formData.append('petImage', blob, file.name);
        //         sendFormData(formData);
        //     } catch (error) {
        //         console.error('File access error:', error.message || error);
        //         setErrorMessage('Failed to access file.');
        //         setLoading(false);
        //     }
        // } else {
        //     // Web logic remains unchanged
        //     formData.append('petImage', selectedFiles[0]);
        //     sendFormData(formData);
        // }

        sendFormData(formData);

    };

    const sendFormData = (formData) => {
        // fetch(`${env.api_url}/lost-pets`, {
        //     method: 'POST',
        //     headers: {
        //         'Authorization': `Bearer ${accessToken}`,
        //     },
        //     body: formData
        // }).then(response => {
        //     if (!response.ok) {
        //         throw new Error('bad response');
        //     }

        //     const reader = response.body.getReader();
        //     const contentLength = +response.headers.get('Content-Length');
        //     let receivedLength = 0;

        //     const stream = new ReadableStream({
        //         start(controller) {
        //             function push() {
        //                 reader.read().then(({ done, value }) => {
        //                     if (done) {
        //                         controller.close();
        //                         return;
        //                     }
        //                     receivedLength += value.length;
        //                     setProgress((receivedLength / contentLength) * 100);
        //                     controller.enqueue(value);
        //                     push();
        //                 });
        //             }
        //             push();
        //         }
        //     });

        //     return new Response(stream);
        // }).then(response => response.json())
        //     .then(data => {
        //         setSuccessMessage('This pet has been registered as lost. Thank you for your help.');
        //         document.getElementById("myForm").reset();
        //         setSelectedPetBreed([]);
        //         setSelectedPetColor([]);
        //         setSelectedPetType('');
        //         uploaderRef.current.resetState();
        //     }).catch(error => {
        //         setErrorMessage('An unexpected error occurred. Please try again later.');
        //         logger.log('Error:', error);
        //     }).finally(() => {
        //         setLoading(false);
        //     });

        fetch(`${env.api_url}/lost-pets`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ownerId: userId,
                petType: formData.get('petType'),
                petBreed: formData.get('petBreed'),
                petColor: formData.get('petColor'),
                location: formData.get('location'),
                petDescription: formData.get('petDescription'),
                name: formData.get('name'),
                isFound: formData.get('isFound'),
                radius: formData.get('radius'),
                locationDetails: formData.get('locationDetails')
            })
            }).then(res => {
                if (!res.ok) {
                    logger.log('Failed to fetch');
                }
                return res.json();
            }).then(res => {
                setSuccessMessage('This pet has been registered as lost. Thank you for your help.');
                document.getElementById("myForm").reset();
                setSelectedPetBreed([]);
                setSelectedPetColor([]);
                setSelectedPetType('');
                uploaderRef.current.resetState();
            }).catch(err => {
                logger.log(err);
                setErrorMessage('An unexpected error occurred. Please try again later.');
            }).finally(() => {
                setLoading(false);
            });
    };

    const handleFilesSelected = (files) => {
        setSelectedFiles(files);
        logger.log(files);
    };

    return (
        <>
            {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
            {loading && (
                <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                        {progress}%
                    </div>
                </div>
            )}
            <div className="formContainer">
                <h2>I found a pet</h2>
                <form onSubmit={handleSubmitForm} id="myForm">
                    <div className="mb-3">
                        <label htmlFor="petType" className="form-label">Pet Type</label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="petType"
                                id="pet_type1"
                                value="dogs"
                                checked={selectedPetType === 'dogs'}
                                onChange={(e) => setSelectedPetType(e.target.value)}
                                disabled={loading} // Disable input during submission
                            />
                            <label className="form-check-label" htmlFor="pet_type1">
                                Dog
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="petType"
                                id="pet_type2"
                                value="cats"
                                checked={selectedPetType === 'cats'}
                                onChange={(e) => setSelectedPetType(e.target.value)}
                                disabled={loading} // Disable input during submission
                            />
                            <label className="form-check-label" htmlFor="pet_type2">
                                Cat
                            </label>
                        </div>
                    </div>

                    {/* Pet Breed Select Dropdown */}
                    <div className="mb-3">
                        <label htmlFor="petBreed" className="form-label">Pet Breed</label>
                        <select
                            multiple={true}
                            className="form-select"
                            id="petBreed"
                            aria-label="Select Pet Type"
                            value={selectedPetBreed}
                            onChange={(e) => handleMultiSelectBreedChange(e)}
                            disabled={loading} // Disable select during submission
                        >
                            {
                                !selectedPetType && <option value="">Select Pet Breed</option>
                            }
                            {selectedPetType && typeSelections[selectedPetType]?.map((breed) => (
                                <option key={breed.name} value={breed.name}>{breed.name}</option>
                            ))}
                        </select>
                    </div>

                    {/* Pet Color Select Dropdown */}
                    <div className="mb-3">
                        <label htmlFor="petColor" className="form-label">Pet Color</label>
                        <select
                            multiple={true}
                            className="form-select"
                            id="petColor"
                            aria-label="Select Pet Color"
                            value={selectedPetColor}
                            onChange={(e) => handleMultiSelectColorChange(e)}
                            disabled={loading} // Disable select during submission
                        >
                            {
                                petColors.length === 0 && <option value="">Select Pet Color</option>
                            }
                            {petColors.map((color) => (
                                <option key={color} value={color}>{color}</option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="petLostArea" className="form-label">Postal code where found</label>
                        <input
                            className="form-control"
                            type="text"
                            id="petLostArea"
                            disabled={loading} // Disable input during submission
                        />
                    </div>

                    {/* Buttons for Gallery or Camera */}
                    {isCapacitor && (
                        <div className="mb-3">
                            <button type="button" className="btn btn-secondary" onClick={() => uploaderRef.current.selectFromGallery()} disabled={loading}>
                                Select from Gallery
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={() => uploaderRef.current.takePicture()} disabled={loading}>
                                Take a Picture
                            </button>
                        </div>
                    )}

                    <div className="mb-3">
                        <label htmlFor="petImage" className="form-label">Pet Image</label>
                        <ImageUploadField onFilesSelected={handleFilesSelected} allowMultiple={false} ref={uploaderRef} disabled={loading} />
                    </div>

                    <button className="btn btn-secondary" onClick={(e) => { e.preventDefault(); navigate(-1); }} disabled={loading}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
            </div>
        </>
    );
}

export default FoundPetForm;

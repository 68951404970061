export const petColors = [
    "Black",
    "White",
    "Brown (Chocolate)",
    "Golden",
    "Red",
    "Blue",
    "Grey",
    "Brindle",
    "Merle",
    "Gray (Blue)", // Note: For cats, "Gray" is often referred to as "Blue"
    "Orange (Ginger)",
    "Cream",
    "Cinnamon",
    "Fawn",
    "Silver"
];
import { env } from '../../config/env';
import { humanDate } from '../../utility/dates';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

const DialogTemplatePetResponse = (props) => {
    const [isReplyMode, setIsReplyMode] = useState(false);
    const navigate = useNavigate();
    const { modalData } = props;

    return (
        <div>
            <div className="mb-1">From: {modalData.name}</div>
            <div className="mb-4">Date: {humanDate(modalData.createdDate)}</div>
            <div className="mb-4">
                <img className="rounded mx-auto d-block img-fluid" style={{ maxHeight: '250px' }} src={`${env.pet_img_url}/${modalData.metadata.imgFileName}`} alt={`${modalData.metadata.petStatus} Pet`} />
            </div>
            <div className="d-grid gap-1 mb-2">
                <a href="#lost-pet-profile"
                    className="btn btn-lg btn-info"
                    onClick={() => {
                        navigate(`/lost-pet-profile/${modalData.metadata.lafId}`)
                    }}
                >
                    View Pet Profile
                </a>
            </div>
            <div className="d-grid gap-1 mb-4">
                <a
                    className="btn btn-lg btn-info"
                    onClick={() => {
                        setIsReplyMode(!isReplyMode);
                    }}
                >
                    {!isReplyMode ? 'Reply to this message' : 'Cancel reply'}
                </a>
                {
                    isReplyMode &&
                    <div className="mb-3">
                        <label htmlFor="petImage" className="form-label">Message reply:</label>
                        <textarea className="form-control" id="petMessage" rows="4" onChange={props.handleMessageChange}></textarea>
                    </div>
                }
            </div>

            <div className="mb-1">{modalData.msgBody}</div>
        </div>
    );
}

export default DialogTemplatePetResponse;

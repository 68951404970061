import React, { useState } from 'react';
import SwipeCard from '../components/SwipeCard';

const cards = [
    {
        cardsArray: [
            { title: "Card 1", content: "Card 1 content", image: "https://picsum.photos/200", userId: 1 },
            { title: "Card 2", content: "Card 2 content", image: "https://picsum.photos/201", userId: 2 },
            { title: "Card 3", content: "Card 3 content", image: "https://picsum.photos/202", userId: 3 }
        ]
    },
    {
        cardsArray: [
            { title: "Card 4", content: "Card 4 content", image: "https://picsum.photos/203", userId: 4 },
            { title: "Card 5", content: "Card 5 content", image: "https://picsum.photos/204", userId: 5 },
            { title: "Card 6", content: "Card 6 content", image: "https://picsum.photos/205", userId: 6 }
        ]
    },
    {
        cardsArray: [
            { title: "Card 7", content: "Card 7 content", image: "https://picsum.photos/206", userId: 7 },
            { title: "Card 8", content: "Card 8 content", image: "https://picsum.photos/207", userId: 8 },
            { title: "Card 9", content: "Card 9 content", image: "https://picsum.photos/208", userId: 9 }
        ]
    }
];

const Search = () => {
    const [profileIndex, setProfileIndex] = useState(0);
    const [cardIndex, setCardIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const handleSwipe = (dir) => {
        console.log(`Swiped ${dir}`);
        switch (dir) {
            case 'left':
            case 'right':
                setProfileIndex(prev => {
                    const newIndex = (prev + (dir === 'left' ? -1 : 1) + cards.length) % cards.length;
                    setCardIndex(0); // Reset card index when changing profiles
                    return newIndex;
                });
                break;
            case 'up':
                setCardIndex(0); // Move to the first card of the current profile
                break;
            case 'down':
                setCardIndex(cards[profileIndex].cardsArray.length - 1); // Move to the last card of the current profile
                break;
        }
    };

    const handleTap = (side) => {
        if (!isDragging) { // Only handle taps when not dragging
            setCardIndex(prev => {
                const newIndex = prev + (side === 'left' ? -1 : 1);
                return (newIndex + cards[profileIndex].cardsArray.length) % cards[profileIndex].cardsArray.length;
            });
        }
    };

    console.log(`Profile: ${profileIndex}, Card: ${cardIndex}`);

    return (
        <div 
            onClick={(e) => {
                const rect = e.target.getBoundingClientRect();
                const x = e.clientX - rect.left;
                if (x < rect.width / 2) handleTap('left');
                else handleTap('right');    
            }}
            className="pet-swiper"    
        >
            <SwipeCard
                onSwipe={handleSwipe}
                directionsConfig={{ left: true, right: true, up: true, down: false }}
                setIsDragging={setIsDragging}
            >
                {/* edit here */}
                <div>
                    {/* <h1>Profile Index: { profileIndex }</h1>
                    <h3>{cards[profileIndex].cardsArray[cardIndex].title}</h3>
                    <p>{cards[profileIndex].cardsArray[cardIndex].content}</p> */}
                    <img className="slider"
                        src={cards[profileIndex].cardsArray[cardIndex].image}
                        alt={cards[profileIndex].cardsArray[cardIndex].title}
                        onMouseDown={e => e.preventDefault()} // Prevent image from capturing drag
                    />
                </div>
                
                <div className="carousel-controls">
                    <div className="control-item">
                        <i className="flaticon flaticon-undo"></i>
                        <span className="control-text">Back</span>
                    </div>
                    <div className="control-item">
                        <i className="flaticon flaticon-information"></i>
                        <span className="control-text">Details</span>
                    </div>
                    <div className="control-item">
                        <i className="flaticon flaticon-email"></i>
                        <span className="control-text">Message</span>
                    </div>
                    <div className="control-item">
                        <i className="flaticon flaticon-star"></i>
                        <span className="control-text">Save</span>
                    </div>
                    <div className="control-item">
                        <i className="flaticon flaticon-redo"></i>
                        <span className="control-text">Next</span>
                    </div>
                </div>
                
                {/** stop edit here */}
            </SwipeCard>
        </div>
    );
}

export default Search;

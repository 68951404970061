// I want to create a context that will store and or pole for the users basic details like name, email, phone number, etc. This context will be used to display the users details in the footer component and also in the header component. I want to be able to access the message count in the event of any unread messages
import { createContext, useContext, useState, useEffect } from 'react';
import { env } from '../config/env';
const { useAuth } = require('../context/AuthContext');
import logger from '../utility/logger';

export const UserContext = createContext('');

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const {
        isAuthenticated,
        userId,
        accessToken
    } = useAuth();

    const [user, setUser] = useState({
        userId: 0,
        name: '',
        profile_img: '',
        postalCode: '',
        totalMessages: 0,
        unreadMessages: 0
    });

    const fetchCounts = () => {
        if (!isAuthenticated || !userId) {
            return;
        }

        fetch(`${env.api_url}/me/${userId}/msg-count`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch unread messages');
                }
                return response.json();
            })
            .then(res => {
                setUser(prevUser => {
                    return {
                        ...prevUser,
                        totalMessages: res.data.totalMessages,
                        unreadMessages: res.data.unreadMessages === null ? 0 : res.data.unreadMessages
                    };
                });
            })
            .catch(error => {
                logger.log('Failed to fetch unread messages:', error);
            });
    }

    // fetch user details from the server if user is logged in according to AuthContext
    useEffect(() => {
        if (isAuthenticated) {
            fetch(`${env.api_url}/me/${userId}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch user details');
                    }
                    return response.json();
                })
                .then(res => {
                    setUser({
                        userId: res.data.user_id,
                        name: res.data.name,
                        profile_img: res.data.profile_img,
                        postalCode: res.data.postalCode,
                        totalMessages: res.data.totalMessages,
                        unreadMessages: res.data.unreadMessages === null ? 0 : res.data.unreadMessages
                    });
                })
                .catch(error => {
                    logger.log('Failed to fetch user details:', error);
                });
        }
    }, [isAuthenticated, userId]);

    //unset user details if user logs out
    useEffect(() => {
        if (!isAuthenticated) {
            setUser({
                userId: 0,
                name: '',
                profile_img: '',
                postalCode: '',
                totalMessages: 0,
                unreadMessages: 0
            });
        }
    }, [isAuthenticated]);

    // poll for unread messages every 5 minutes
    useEffect(() => {
        const interval = setInterval(() => {
            fetchCounts();
        }, 1000 * 60 * 5); // every 5 minutes

        return () => clearInterval(interval);
    }, [userId]);

    return (
        <UserContext.Provider value={{ user, setUser, fetchCounts }}>
            {children}
        </UserContext.Provider>
    );
}

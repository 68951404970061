import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { AuthProvider, useAuth } from './context/AuthContext';
import { UserProvider } from './context/UserContext';
import { ConfigProvider } from './context/ConfigContext';
import {env as config} from './config/env';
import Header from './components/header';
import Footer from './components/footer';
import PrivateRoute from './utility/PrivateRoute';
import PublicOnlyRoute from './utility/PublicOnlyRoute';
import './assets/styles/styles.css';
// import './assets/fonts/flaticon_pets.css';

// Pages
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Registration from './pages/registration';
import LostPetProfile from './pages/lost-pet-profile';
import PetProfile from './pages/pet-profile';
import Welcome from './pages/welcome';
import LostPetForm from './pages/lost-pet';
import FoundPetForm from './pages/found-pet';
import Pets from './pages/pets';
import Search from './pages/search';
import HumanProfile from './pages/human-profile';
import RegisterPetForm from './pages/register-pet';
import ForgotPasswordForm from './pages/forgot-password';
import Messages from './pages/messages';

import {
//   BrowserRouter as Router,
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    const { refreshAccessToken, isAuthenticated } = useAuth();
    const [isNative, setIsNative] = useState(false);

    useEffect(() => {
        // Configure the StatusBar when the app is running on a native platform
        if (Capacitor.isNativePlatform()) {
            setIsNative(true);

            StatusBar.setBackgroundColor({ color: "#0D8FF2" });
            StatusBar.setOverlaysWebView({ overlay: false });
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            const interval = setInterval(() => {

                    refreshAccessToken();

            }, 1000 * 60 * 15); // Refresh every 15 minutes
            return () => clearInterval(interval);
        }
    }, [isAuthenticated, refreshAccessToken]);

    const content = (
        <div className="lpcontainer">
            <Routes>
                {/* Public routes */}
                <Route path="/" element={<Login />} />
                {/* Public only routes */}
                <Route element={<PublicOnlyRoute />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/registration" element={<Registration />} />
                    <Route path="/forgot-password" element={<ForgotPasswordForm />} />
                </Route>
                {/* Private routes */}
                <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/lost-pet-profile/:lafId" element={<LostPetProfile />} />
                    <Route path="/pet-profile/:petId" element={<PetProfile />} />
                    <Route path="/lost-pet" element={<LostPetForm />} />
                    <Route path="/found-pet" element={<FoundPetForm />} />
                    <Route path="/register-pet" element={<RegisterPetForm />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="/pets" element={<Pets />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/profile" element={<HumanProfile />} />
                    <Route path="/messages" element={<Messages />} />
                </Route>

                {/* Catch all */}
                <Route path="*" element={<div>Not Found</div>} />

            </Routes>
        </div>
    );

    return (
        <ConfigProvider config={config}>

                <AuthProvider>
                    <UserProvider>
                    <Router>
                        {isNative && <div className="safe-area" />}
                        <Header />
                        {content}
                        <Footer />
                    </Router>
                    </UserProvider>
                </AuthProvider>

        </ConfigProvider>
    );
}

export default App;

import React from 'react';

export const ConfigContext = React.createContext();

export const ConfigProvider = ({ children, config }) => {
    return (
      <ConfigContext.Provider value={config}>
        {children}
      </ConfigContext.Provider>
    );
  };

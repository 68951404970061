import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';

const Footer = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const { user } = useUser();
    const location = useLocation();
    const {pathname} = location;

    const [menuToggle, setMenuToggle] = useState(false);

    if (!isAuthenticated) {
        return null;
    }

    const handleToggleClick = (e, ahref) => {
        e.preventDefault();
        setMenuToggle(false);
        navigate(ahref);
        window.scrollTo(0, 0);
    }

    const handleNavClick = (e, ahref) => {
        e.preventDefault();
        setMenuToggle(false);
        navigate(ahref);
        window.scrollTo(0, 0);
    }

    return (
        <nav className="bottom-navigation">
            <ul>
                <li><a href="#none" onClick={(e) => { handleNavClick(e, '/dashboard');}}><i className={`${pathname === '/dashboard' ? 'icon-active ' : ''} flaticon flaticon-home`}></i></a></li>
                <li><a href="#none" onClick={(e) => { handleNavClick(e, '/search');}}><i className={`${pathname === '/search' ? 'icon-active ' : ''} flaticon flaticon-search-1`}></i></a></li>
                <div
                    className={`menuToggle${menuToggle ? ' active' : ''}`}
                    onClick={() => setMenuToggle(!menuToggle)}
                ></div>

                <div className="menu">
                    <ul>
                        <li style={{ '--i': '0.1s' }}><a href="#none" onClick={(e) => { handleToggleClick(e, '/found-pet');}}><i className="flaticon flaticon-location"></i>Found a Pet</a></li>
                        <li style={{ '--i': '0.2s' }}><a href="#none" onClick={(e) => { handleToggleClick(e, '/lost-pet');}}><i className="flaticon flaticon-search"></i>Lost a Pet</a></li>
                    </ul>
                </div>
                <li><a href="#none" onClick={(e) => { handleNavClick(e, '/pets');}}><i className={`${pathname === '/pets' ? 'icon-active ' : ''} flaticon flaticon-paw`}></i></a></li>
                <li>
                    <a href="#none" onClick={(e) => { handleNavClick(e, '/messages');}}>
                        <i className={`${pathname === '/messages' ? 'icon-active ' : ''} flaticon flaticon-message`} />
                        {
                            user.unreadMessages > 0 &&
                                <span className="badge">
                                    {user.unreadMessages}
                                </span>
                        }
                    </a>
                </li>
            </ul>
        </nav>
    );

}

export default Footer;
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaEnvelope, FaEye, FaEyeSlash } from 'react-icons/fa';
// TODO: Determine if this "welcome page" has been seen before

const LoginForm = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    const [passwordShown, setPasswordShown] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [touched, setTouched] = useState({
        email: false,
        password: false
    });
    const [validity, setValidity] = useState({
        email: true,
        password: true
    });

    const canSubmit = formData.email && formData.password && validity.email && validity.password;

    const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isValidPassword = password => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{10,}$/.test(password);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'email') {
            setValidity({ ...validity, email: isValidEmail(value) });
        } else if (name === 'password') {
            setValidity({ ...validity, password: isValidPassword(value) });
        }
    };

    const handleBlur = e => {
        const { name } = e.target;
        setTouched({ ...touched, [name]: true });
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!canSubmit) {
            return;
        }

        await login(formData.email, formData.password);
        // If first time, navigate to welcome page
        // else navigate to dashboard
        // TODO: Fix hardcoded value to if-else statement
        // for determining if user has seen welcome page
        navigate('/welcome');
    };

    const handleGoToRegister = (e) => {
        e.preventDefault();
        navigate('/registration');
    };

    const handleGoToForgotPw = (e) => {
        e.preventDefault();
        navigate('/forgot-password');
    }

    return (
        <>
            <div className="container">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><FaEnvelope /></span>
                        </div>
                        <input
                            type="email"
                            className={`form-control ${touched.email && !validity.email ? 'is-invalid' : ''}`}
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.email && !validity.email && <div className="invalid-feedback">Please enter a valid email. Example: user@example.com</div>}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div className="input-group">
                        <input
                            type={passwordShown ? "text" : "password"}
                            className={`form-control ${touched.password && !validity.password ? 'is-invalid' : ''}`}
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisiblity}>
                            {passwordShown ? <FaEye /> : <FaEyeSlash />}
                            </button>
                        </div>
                        {touched.password && !validity.password && (
                            <div className="invalid-feedback">
                            Remember: Your password should have contained at least one uppercase, one lowercase, and one number or special character.
                            </div>
                        )}
                        </div>
                    </div>

                    <div className="d-grid gap-2 mt-4">
                        <button type="submit" className="btn btn-lg btn-primary" disabled={!canSubmit}>Login</button>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-link" onClick={(e) => handleGoToRegister(e)}>Create Account</button> | <button className="btn btn-link" onClick={(e) => handleGoToForgotPw(e)}>Forgot Password</button>
                    </div>
                </form>
            </div>
      </>
    );
};

export default LoginForm;

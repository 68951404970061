import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import { isValidEmail } from '../utility/regex';
import { env } from '../config/env';
import logger from "../utility/logger";
// TODO: Determine if this "welcome page" has been seen before

const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [formData, setFormData] = useState({
        email: '',
    });
    const [touched, setTouched] = useState({
        email: false,
    });
    const [validity, setValidity] = useState({
        email: true,
    });

    const canSubmit = formData.email;

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'email') {
            setValidity({ ...validity, email: isValidEmail(value) });
        }
    };

    const handleBlur = e => {
        const { name } = e.target;
        setTouched({ ...touched, [name]: true });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!canSubmit) {
            return;
        }

        const email = e.target.email.value;

        if (!email) {
            setErrorMessage('All fields are required');
            return;
        }

        const formData = new FormData();
        formData.append('email', email)

        await fetch(`${env.api_url}/forgot-password`, {
            headers: {
                enctype: 'multipart/form-data',
            },
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('bad response');
                }
                return response.json();
            })
            .then((data) => {
                formData.forEach((value, key) => {
                    logger.log(key, value);
                    formData.delete(key);
                });

                document.getElementById("myForm").reset();
                setSuccessMessage('Forgot password requested successfully');
            })
            .catch((error) => {
                setErrorMessage('An unexpected error occurred. Please try again later.');
                logger.log('Error:', error);
            });
    };

    const handleGoToRegister = (e) => {
        navigate('/registration');
    };

    const handleGoToLogin = (e) => {
        navigate('/login');
    }

    return (
        <>
            {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
            <div className="container">
                <h2>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><FaEnvelope /></span>
                            </div>
                            <input
                                type="email"
                                className={`form-control ${touched.email && !validity.email ? 'is-invalid' : ''}`}
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {touched.email && !validity.email && <div className="invalid-feedback">Please enter a valid email. Example: user@example.com</div>}
                        </div>
                    </div>

                    <div className="d-grid gap-2 mt-4">
                        <button type="submit" className="btn btn-lg btn-primary" disabled={!canSubmit}>Submit</button>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-link" onClick={(e) => handleGoToRegister(e)}>Create Account</button> | <button className="btn btn-link" onClick={(e) => handleGoToLogin(e)}>Login</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ForgotPasswordForm;

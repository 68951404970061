import { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

const ImageUploadField = forwardRef(({ onFilesSelected, allowMultiple }, ref) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [isCapacitor, setIsCapacitor] = useState(false);

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            setIsCapacitor(true);
        }
    }, []);

    const handleFieldClick = () => {
        if (!isCapacitor) {
            fileInputRef.current.click();
        }
    };

    // Function to select an image from the gallery using Capacitor
    const selectFromGallery = async () => {
        try {
            const image = await Camera.getPhoto({
                quality: 90,
                allowEditing: true,
                resultType: CameraResultType.Uri,
                source: CameraSource.Photos, // Select from gallery
            });

            const file = {
                name: image.path.split('/').pop(),
                path: Capacitor.convertFileSrc(image.path) || image.webPath || image.path, // Convert the path using Capacitor.convertFileSrc
            };

            setSelectedFiles([file]);
            onFilesSelected([file]); // Notify parent component
        } catch (error) {
            console.error('Error selecting image from gallery:', error);
        }
    };

    // Function to take a picture using the camera with Capacitor
    const takePicture = async () => {
        try {
            const image = await Camera.getPhoto({
                quality: 90,
                allowEditing: true,
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera, // Take picture using the camera
            });

            const file = {
                name: image.path.split('/').pop(),
                path: Capacitor.convertFileSrc(image.path) || image.webPath || image.path, // Convert the path using Capacitor.convertFileSrc
            };

            setSelectedFiles([file]);
            onFilesSelected([file]); // Notify parent component
        } catch (error) {
            console.error('Error taking picture:', error);
        }
    };

    const handleFileChange = (event) => {
        if (event.target.files) {
            const files = Array.from(event.target.files);
            const updatedFiles = files.map((file) => ({
                name: file.name,
                path: URL.createObjectURL(file), // Use createObjectURL for web
            }));
            setSelectedFiles(updatedFiles);
            onFilesSelected(files); // Notify parent component
        }
    };

    useImperativeHandle(ref, () => ({
        resetState() {
            setSelectedFiles([]);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        },
        selectFromGallery, // Expose method to parent
        takePicture, // Expose method to parent
    }));

    return (
        <div>
            <div
                className="border d-flex justify-content-center align-items-center"
                style={{ cursor: 'pointer', height: '100px', borderStyle: 'dotted', borderWidth: '2px', borderColor: '#ced4da' }}
                onClick={handleFieldClick}
            >
                <div>
                    <span className="fa fa-cloud-upload-alt text-muted" style={{ fontSize: '24px' }}></span>
                    <p className="text-muted">Upload Image</p>
                </div>
                {!isCapacitor && (
                    <input
                        ref={fileInputRef}
                        type="file"
                        multiple={allowMultiple || false}
                        accept="image/png, image/jpeg, image/gif"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                )}
            </div>

            {selectedFiles.length > 0 && (
                <div style={{ display: 'flex', gap: '10px', padding: '10px', flexDirection: 'row' }}>
                    {selectedFiles.map((file) => (
                        <div key={file.name}>
                            <img src={file.path} alt={file.name} style={{ width: '100px', height: '100px' }} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export default ImageUploadField;

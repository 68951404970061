import { useEffect, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { env } from '../config/env';
import { AuthContext } from '../context/AuthContext';
import logger from "../utility/logger";
import DialogModal from '../components/dialog-modal';

const Pets = () => {
    const navigate = useNavigate();
    const { userId, accessToken } = useContext(AuthContext);
    const [pets, setPets] = useState([])
    const [loading, setLoading] = useState(true);
    const [viewMyPets, setViewMyPets] = useState(true);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [actionPetId, setActionPetId] = useState(null);
    const baseUrl = `${env.api_url}`
    const urlParams = new URLSearchParams({
        ownerId: userId
    });
    const myPetsUrl = `${baseUrl}/my-pets?${urlParams.toString()}`;
    const lostFoundUrl = `${baseUrl}/lost-pets?${urlParams.toString()}`;

    const fetchPets = useCallback((areMine = true) => {
        fetch(areMine ? myPetsUrl : lostFoundUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                setLoading(false);
                throw new Error('Bad response');
            }
            setLoading(false);
            return response.json();
        }
        ).then(res => {
            setLoading(false);
            setPets(res.data);
        }).catch(error => {
            setLoading(false);
            logger.error(error);
        });
    }, [accessToken, lostFoundUrl, myPetsUrl]) // Add an empty array as the second argument to useCallback

    const handleTabChange = (areMine) => {
        setLoading(true);
        setPets([]);
        setViewMyPets(areMine);
        fetchPets(areMine);
    }

    useEffect(() => {
        fetchPets(true);
    }, [fetchPets]);

    const handleCloseModal = () => {
        setIsConfirmModalOpen(false);
        setActionPetId(null);
    }

    const handleCloseModalSubmit = () => {
        fetch(`${env.api_url}/my-pets/${userId}/${actionPetId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (!response.ok) {
                throw new Error('Bad response');
            }

            fetchPets(viewMyPets);
            setIsConfirmModalOpen(false);
            setActionPetId(null);
        });
    }

    return (
        <>
            <div className="pets-container">
                <div className="header">
                    <h1>Pets</h1>
                    <div className="add-icon">
                        {
                            viewMyPets ?
                                <Link className="links" to="/register-pet"><span className="icon-pet"><i className="flaticon flaticon-footprints"></i></span> Add my Pet</Link>
                                :
                                <>
                                    {/* <Link to="/lost-pet">Add Lost Pet</Link> */}
                                    <Link className="links" to="/found-pet"><span className="icon"><i className="flaticon flaticon-location"></i></span>Add Found Pet</Link>
                                </>
                        }
                    </div>
                </div>


                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${viewMyPets ? 'active' : ''}`}
                            type="button"
                            role="tab"
                            aria-selected="true"
                            onClick={() => handleTabChange(true)}
                            disabled={loading}
                        >
                            My Pets
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${!viewMyPets ? 'active' : ''}`}
                            type="button"
                            role="tab"
                            aria-selected="true"
                            onClick={() => handleTabChange(false)}
                            disabled={loading}
                        >
                            Lost/Found
                        </button>
                    </li>
                </ul>

                {
                    loading ?
                        <div className="loading">Loading...</div>
                        :
                        <div className="category my-pets">
                            {
                                pets.length === 0 ?
                                    <div className="no-pets">No pets found</div>
                                    :
                                    pets.map((pet, index) => (
                                        <div
                                            className="pet-card" key={index}
                                            onClick={() => {
                                                if (viewMyPets) {
                                                    navigate(`/pet-profile/${pet.id}`)
                                                } else {
                                                    navigate(`/lost-pet-profile/${pet.lafId}`)
                                                }
                                            }}
                                        >
                                            <img src={`${env.pet_img_url}/${pet.imgFileName}`} alt={pet.name} />
                                            <div className="pet-info">
                                            <button
                                                className="delete-button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setIsConfirmModalOpen(true);
                                                    setActionPetId(pet.id);
                                                }}
                                            >
                                                ×
                                            </button>
                                                {viewMyPets && <h3>{pet.name}</h3>}
                                                <p>Breed: {pet.petBreed}</p>
                                                <p>Color: {pet.colors}</p>
                                                {!viewMyPets && <p>Location: {pet.city}, {pet.state_abbr}. {pet.postal_code}</p>}
                                                {!viewMyPets && <p>Status: I {pet.isFound === 1 ? 'found' : 'lost'} this pet</p>}
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                }
            </div>
            {
                isConfirmModalOpen &&
                <DialogModal
                    title="Remove pet"
                    body={
                        <div>
                            <p>Are you sure you want to remove this pet?</p>
                        </div>
                    }
                    closeDialog={handleCloseModal}
                    closeDialogSubmit={handleCloseModalSubmit}
                    closeText="Cancel"
                    closeSubmitText="Continue"
                    hideCloseOnly={false}
                />
            }
        </>
    );
}

export default Pets;

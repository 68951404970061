export const humanDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'UTC' };
    return date.toLocaleString(undefined, options);
}

export const humanDateShort = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
    return date.toLocaleString(undefined, options);
}

export const humanDateShorter = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' };
    return date.toLocaleString(undefined, options);
}

export const humanDateShortest = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'UTC' };
    return date.toLocaleString(undefined, options);
}

export const humanDateShortestNoTime = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' };
    return date.toLocaleString(undefined, options);
}
import { useNavigate } from 'react-router-dom';
// import welcome from '../assets/images/welcome.svg';

const Welcome = () => {
    const navigate = useNavigate();
    return (
    <>
        <img src='./static/welcome.svg' alt="Welcome to LocatePets" />

        <p>
        Welcome to LocatePets, the free app dedicated to reuniting lost pets with their loving owners. <br /><br />Whether you've lost a pet or found one, we're here to help you every step of the way.
        </p>

        <div className="d-grid gap-2">
            <button
                className="btn btn-lg btn-lost"
                onClick={() => navigate('/lost-pet')}
            >
                <span className="icon"><i className="flaticon flaticon-search"></i></span><span className="button-text">I lost my pet</span>
            </button>
            <button
                className="btn btn-lg btn-light"
                onClick={() => navigate('/found-pet')}
            >
                 <span className="icon"><i className="flaticon flaticon-location"></i></span><span className="button-text">I found a pet</span>
            </button>
        </div>

        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
                className="btn btn-link"
                onClick={() => navigate('/dashboard')}
            >
                Skip
            </button>
        </div>
    </>
    );
}

export default Welcome;

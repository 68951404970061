import { useState, useContext } from 'react';
import { AuthContext, useAuth } from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { env } from '../config/env';
import DialogModal from '../components/dialog-modal';

const Card = (props) => {
    const navigate = useNavigate();
    const { userId, accessToken } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOkToSubmit, setIsOkToSubmit] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [petMessage, setPetMessage] = useState('');

    const handleCloseModal = () => {
        setErrorMessage('');
        setSuccessMessage('');
        setPetMessage('');
        setIsModalOpen(false);
        setIsOkToSubmit(false);
    }

    const handleMessageChange = (e) => {
        if(e.target.value.length <= 0){
            setIsOkToSubmit(false);
        }
        setIsOkToSubmit(true);
        setPetMessage(e.target.value);
    }

    const handleCloseModalSubmit = async () => {
        // Send message
        setErrorMessage('');
        setSuccessMessage('');

        if (!petMessage) {
            setErrorMessage('Please enter a message');
            return;
        }

        // createMessageEntry(senderUserId = 0, recipientUserId = 0, data = {}, $metadata = '')
        // { x: userId, y: recipientUserId, z: message, m: metadata }

        await fetch(`${env.api_url}/messages`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                x: userId,
                y: pet.ownerId,
                s: `Message about ${pet.isFound ? 'Found' : 'Lost'} pet listing #${pet.lafId}`,
                m: petMessage,
                metadata: JSON.stringify({ ...pet, messageType: 'pet-response'}),
                chain: ''
            })
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Failed to send message');
                }
                return res.json();
            })
            .then(res => {
                setIsOkToSubmit(true);
                setIsModalOpen(false);
            })
            .catch(err => {
                console.error(err);
            });

        setIsModalOpen(false);
        setIsOkToSubmit(false);
    }


    const { pet } = props;
    const handleClick = () => {
        navigate(`/lost-pet-profile/${pet.lafId}`);
    }

    const petStatus = !pet.isFound ? 'Lost' : 'Found';
    // console.log(pet.ownerId === userId, pet.ownerId, userId)
    return (
        <>
            <div className="dashboard-card">
                <div className="status-pill">{petStatus}</div>
                <img src={`${env.pet_img_url}/${pet.imgFileName}`} alt={`${petStatus} Pet`} />

                <div className="card-overlay">
                    <div className="mb-1">Description: {pet.details ? pet.details : 'No description available'}</div>
                    <div>Location Found: {pet.city}, {pet.state_abbr}.</div>
                    <button className="more-info" onClick={handleClick}>More Info</button>
                    <button
                        onClick={() => {setIsModalOpen(true)}}
                        disabled={pet.ownerId === parseInt(userId)}
                    >Contact</button>
                </div>
            </div>
            {
                isModalOpen && <DialogModal
                    title="Contact "
                    body={
                        <div className="mb-3">
                            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                            {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
                            <label htmlFor="petImage" className="form-label">Send a message about this pet</label>
                            <textarea className="form-control" id="petMessage" rows="4" onChange={handleMessageChange}></textarea>
                        </div>
                    }
                    closeDialog={() => handleCloseModal()}
                    closeDialogSubmit={() => handleCloseModalSubmit()}
                    closeDialogSubmitDisabled={!isOkToSubmit}
                    closeText="Cancel"
                    closeSubmitText="Send Message"
                    hideCloseOnly={false}
                />
            }
        </>
    );
}
export default Card;

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { env } from '../config/env';
import logger from "../utility/logger";
import { AuthContext } from '../context/AuthContext';
import DialogModal from '../components/dialog-modal';

const LostPetProfile = (props) => {
    const navigate = useNavigate();
    const { userId, accessToken } = useContext(AuthContext);
    const { petId } = useParams();
    const [pet, setPet] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [profileNotFound, setProfileNotFound] = useState(false);
    const [isConfirmedNotHomeModalOpen, setIsConfirmedNotHomeModalOpen] = useState(false);

    const handleCloseModal = () => {
        setIsConfirmedNotHomeModalOpen(false);
    }

    const handleCloseModalSubmit = async () => {
        // Confirm action of marking pet as lost or back home
        await fetch(`${env.api_url}/pet-profile`, {
            method: 'PUT',
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                petId: petId,
                isActivelyLost: !pet.isActivelyLost
            }).then(res => {
                if (!res.ok) {
                    logger.error('Failed to update');
                    setError('Failed to update pets status');
                    return;
                }

                return res.json();
            }).then(res => {
                setPet(res.data);
                setIsConfirmedNotHomeModalOpen(false);
            }).catch(err => {
                logger.error(err);
                setError(err);
            })
        });
    }

    const fetchLafPet = async () => {
        setLoading(true);
        setError(false);
        await fetch(`${env.api_url}/pet-profile?petId=${petId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (!res.ok) {
                    logger.error('Failed to fetch');
                    throw new Error('Failed to fetch');
                }
                return res.json();
            })
            .then(res => {
                if (res.data.length === 0) {
                    setProfileNotFound(true);
                    return;
                }

                setPet(res.data);
                setLoading(false);
            })
            .catch(err => {
                logger.error(err);
                setError(err);
            });
    }

    useEffect(() => {
        let isMounted = true; // track whether component is mounted
        fetchLafPet().then(() => {
            if (isMounted) setLoading(false);
        });
        return () => { isMounted = false }; // cleanup function to be called when component unmounts
    }, []);

    if (loading) {
        return <div>Loading...</div>
    }

    if (profileNotFound) {
        return <div>Profile not found</div>
    }

    return (
        <>
            <div className="p-4">
                <div className="d-grid gap-1">
                    <a href="#pets"
                        className="btn btn-lg btn-light"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/pets`)
                        }}
                    >
                        Back to Pets
                    </a>
                </div>
                <div className="pet-profile mt-4">
                    <div className="pet-image-placeholder mb-4">
                        <img className="rounded mx-auto d-block img-fluid" style={{ maxHeight: '450px' }} src={`${env.pet_img_url}/${pet.imgFileName}`} alt={`My Pet`} />
                        <div className="d-grid gap-1">
                            {
                                pet.isActivelyLost && pet.petOwnerId === userId ?

                                    <a href="#pets-home"
                                        className="btn btn-lg btn-info"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsConfirmedHomeModalOpen(true);
                                        }}
                                    >
                                        Mark as back home
                                    </a>
                                    :
                                    <a href="#pets-not-home"
                                        className="btn btn-lg btn-info"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsConfirmedNotHomeModalOpen(true);
                                        }}
                                    >
                                        Mark as lost
                                    </a>
                            }
                        </div>
                    </div>
                    <h1>Name: {!pet.name ? 'Unknown' : pet.name}</h1>
                    <div><strong>Breed:</strong> {pet.petBreed}</div>
                    <div><strong>Color(s): </strong> {pet.colors}</div>
                    <div><strong>Description:</strong> {pet.details ? pet.details : 'No description available'}</div>
                </div>
            </div>
            {
                isConfirmedNotHomeModalOpen && <DialogModal
                    title={pet.isActivelyLost ? "Confirm Pet is Back Home" : "Confirm Pet is Lost"}
                    body={
                        <div className="mb-3">
                            <p>Are you sure you want to mark this pet as {pet.isActivelyLost ? 'back home' : 'lost'}?</p>
                        </div>
                    }
                    closeDialog={() => handleCloseModal()}
                    closeDialogSubmit={() => handleCloseModalSubmit()}
                    closeDialogSubmitDisabled={true}
                    closeText="Cancel"
                    closeSubmitText="Confirm"
                    hideCloseOnly={false}
                />
            }
        </>
    );
}

export default LostPetProfile;

import React, { useState, useEffect, useCallback, useContext, createContext } from 'react';
import { jwtDecode } from 'jwt-decode';  // npm install jwt-decode
import { env } from '../config/env';
import logger from '../utility/logger';

export const AuthContext = createContext('');

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);

    // Not sure this is a great idea, should fix this concept later..
    if (localStorage.getItem('userId') && !userId) {
        setUserId(localStorage.getItem('userId'));
    }

    const login = async (email, password) => {
        try {
            await fetch(`${env.api_url}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Invalid credentials');
                }
                return response.json();
            })
            .then(res => {
                localStorage.setItem('accessToken', res.data.accessToken);
                localStorage.setItem('refreshToken', res.data.refreshToken);
                localStorage.setItem('userId', res.data.userId);
                setAccessToken(res.data.accessToken);
                setIsAuthenticated(true);
                setUserId(res.data.userId);
                return true;
            })
            .catch(error => {
                throw new Error(error);
            });
        } catch (error) {
            logger.log('Login failed:', error);
            return false;
        }
    };

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        localStorage.clear();
        setAccessToken(null);
        setIsAuthenticated(false);
    };

    const refreshAccessToken = useCallback(async (refreshToken) => {
        try {
            await fetch(`${env.api_url}/token-refresh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ refreshToken })
            })
            .then(response => {
                if (!response.ok) {
                    logout();
                    throw new Error('Failed to refresh token');
                }
                return response.json();
            })
            .then(res => {
                localStorage.setItem('accessToken', res.data.accessToken);
                localStorage.setItem('refreshToken', res.data.refreshToken);
                setAccessToken(res.data.accessToken);
                setIsAuthenticated(true);
                return true;
            })
            .catch(error => {
                logout();
                throw new Error(error);
            });
        } catch (error) {
            logger.log('Refresh Token:', error);
            logout(); // Logout user if refresh fails
            return false;
        }
    }, []);

    const validateToken = useCallback(async (accessToken, refreshToken) => {
        try {
            const decoded = jwtDecode(accessToken);
            const currentTime = Date.now() / 1000; // Current time in seconds

            if (decoded.exp < currentTime) {
                // Token expired, attempt to refresh it
                await refreshAccessToken(refreshToken);
            } else {
                // Token is still valid
                setAccessToken(accessToken);
                setIsAuthenticated(true);
            }
        } catch (error) {
            // Error during decoding or token invalid, refresh token
            await refreshAccessToken(refreshToken);
        } finally {
            setLoading(false);  // Loading done after token check
        }
    }, [refreshAccessToken]);

    const updateToken = useCallback(async (accessToken, refreshToken) => {
        try {
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            setAccessToken(accessToken);
        } catch (error) {
            logout();
            logger.log('Failed to update token:', error);
        }
    }, []);

    useEffect(() => {
        const storedAccessToken = localStorage.getItem('accessToken');
        const storedRefreshToken = localStorage.getItem('refreshToken');

        // Check if tokens are present in localStorage
        if (storedAccessToken && storedRefreshToken) {
            // Validate the access token (e.g., check expiration)
            validateToken(storedAccessToken, storedRefreshToken);
        } else {
            // If no tokens, just finish the loading state
            setLoading(false);
        }
    }, [validateToken]);

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            accessToken,
            loading,
            login,
            logout,
            updateToken,
            refreshAccessToken,
            userId
        }}>
            {children}
        </AuthContext.Provider>
    );
};

import {env} from '../config/env';

const canDebug = env.debug || env.environment === 'development';

const logger = {
    log: (...args) => {
        if(canDebug) {
            console.log(...args);
        }
    },
    error: (...args) => {
        if(canDebug) {
            console.error(...args);
        }
    },
    warn: (...args) => {
        if(canDebug) {
            console.warn(...args);
        }
    },
    info: (...args) => {  // Adding the info method
        if(canDebug) {
            console.info(...args);
        }
    }
};

export default logger;

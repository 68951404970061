import { useNavigate } from 'react-router-dom';
const { useAuth } = require('../context/AuthContext');

const Header = () => {
    const {
        isAuthenticated,
        // logout
    } = useAuth();

    const navigate = useNavigate();

    //   const handleLogout = () => {
    //     logout();
    //     window.location.href = '/login';
    //   }



    return (
        <header className="top-header">
            <div className="logo-container-full">
                <img src="./static/logo_header.svg" alt="Logo" />
                {/* {
                isAuthenticated && (<button onClick={handleLogout}>Logout</button>)
                } */}
                <div className="header-right">
                    {
                        isAuthenticated && (
                            <>
                                <i className="flaticon flaticon-settings" onClick={() => navigate('/profile')}></i>
                            </>
                        )
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;

import React from 'react';

// Import your choice of icons for check and cross
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const PasswordCriteria = ({ password }) => {
  // Define the password criteria
  const criteria = [
    { label: 'At least one uppercase letter', isValid: /[A-Z]/.test(password) },
    { label: 'At least one lowercase letter', isValid: /[a-z]/.test(password) },
    { label: 'At least one number', isValid: /\d/.test(password) },
    { label: 'At least one special character', isValid: /[\W_]/.test(password) },
    { label: 'Minimum 10 characters', isValid: password.length >= 10 },
  ];

  return (
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {criteria.map((criterion, index) => (
        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          {criterion.isValid ? (
            <FaCheckCircle style={{ color: 'green', marginRight: '8px' }} />
          ) : (
            <FaTimesCircle style={{ color: 'red', marginRight: '8px' }} />
          )}
          <span>{criterion.label}</span>
        </li>
      ))}
    </ul>
  );
};

export default PasswordCriteria;
